:root {
  --rsbs-content-opacity: 1;
  --rsbs-max-w: var(--layout-max-w);
  --rsbs-ml: env(safe-area-inset-left);
  --rsbs-mr: env(safe-area-inset-right);

  --rsbs-ml: auto;
  --rsbs-mr: auto;
}

[data-rsbs-overlay] {
  z-index: 99;
}

[data-rsbs-header] {
  @apply text-left pb-0 shadow-none px-xs;
}

[data-rsbs-footer] {
  @apply shadow-none px-xs;
}
